/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Container from "./container"
import Arrow from "../images/hero-arrow.svg"
import Link from "./link"
import { toSlug } from "../helpers"

function FeaturedStoryHero() {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        featuredStoriesSequence {
          orderItems {
            contentful_id
            title
            images {
              file {
                url
              }
            }
            author {
              name
            }
          }
        }
      }
    }
  `)

  const featuredStory =
    data.contentfulSite.featuredStoriesSequence.orderItems[0]

  return (
    <div>
      <div
        sx={{
          height: ["80vh", "90vh", "91vh"],
          m: 0,
          width: "100%",
          backgroundColor: "lightgray",
          backgroundImage: featuredStory.images
            ? `url(${featuredStory.images[0].file.url})`
            : "unset",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Container sx={{ py: 0 }}>
          <Link
            link={`/story/${toSlug(featuredStory.title)}-${
              featuredStory.contentful_id
            }`}
          >
            <div
              sx={{
                backgroundColor: "rgba(204,92,69, 0.9)",
                p: ["20px", "20px", "72px"],
              }}
            >
              <h1
                sx={{
                  width: "80%",
                  color: "white",
                  lineHeight: ["40px", "40px", "40px", "80px"],
                  fontSize: ["36px", "36px", "36px", "72px"],
                }}
              >
                {featuredStory.title}
              </h1>
              {featuredStory.author && (
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p sx={{ color: "white", m: 0 }}>
                    By {featuredStory.author.name}
                  </p>
                  <img
                    alt="Arrow"
                    src={Arrow}
                    sx={{ width: ["60px", "60px", "60px", "100px"] }}
                  />
                </div>
              )}
            </div>
          </Link>
        </Container>
      </div>
    </div>
  )
}

export default FeaturedStoryHero
